<template>
  <v-footer app absolute padless>
    <v-card tile color="primary" width="100%">
      <v-card-text class="pa-2 white--text text-center">
        {{ new Date().getFullYear() - 1 }} - {{ new Date().getFullYear() }}
        <strong
          >©. All rights reserved.
          <v-btn class="pl-1 pr-1 text-none" height="40">
            <v-img src="@/assets/logoFooter.png" width="30" />
            Lueder,Larkin & Hunter,LLC
          </v-btn>
        </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "app_footer"
};
</script>

<style scoped></style>
